$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.ImageCarousel__Carousel {
  position: relative;
  letter-spacing: 0;
  word-spacing: 0;
  font-size: 0;
  overflow: hidden;
}

.ImageCarousel__Spacer {
  visibility: hidden;
  width: 100%;
  height: auto;
  pointer-events: none;
}

.ImageCarousel__Inner {
  position: absolute;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  transition: transform 0.5s ease;
}

.ImageCarousel__Item {
  position: absolute;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  @for $i from 1 through 10 {
    &:nth-child(#{$i}) {
      transform: translateX(#{($i - 1) * 100%});
    }
  }
}

.ImageCarousel__Controls {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  pointer-events: none;

  > * {
    pointer-events: auto;
  }

  .Button {
    opacity: 0.85;

    &:first-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:last-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.ImageCarousel__Modal {
  .ModalCard {
    border: none !important;
    border-radius: 15px !important;
    box-shadow: 0 4px 4px rgba(#000, 0.25) !important;
  }

  .ModalCard__Body {
    padding: 0;
  }
}
