$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.LeaderboardContainer {
  display: flex;
  font-weight: 300;

  > aside,
  > div {
    border-radius: $radius;
    padding: $spacing;
  }

  > aside {
    // width: 30%;
    margin-right: $spacing;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > div {
    flex: 1;
  }

  [data-disabled] {
    opacity: 0.35;
    pointer-events: none;
  }
}
