$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.DownloadGameTile {
  position: relative;
  overflow: hidden;
  border-radius: $radius;
  display: flex;
  flex-direction: column;
  user-select: none;

  > figure {
    min-height: 264px;
    position: relative;
    display: none;
  }

  > section {
    padding: $spacing;
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  h3,
  .p {
    margin-bottom: $spacing;
  }

  h3 {
    font-weight: bold;
    font-size: 22px;  
  }

  .p {
    font-size: 12px;
  }

  img {
    object-fit: cover;
  }
  
  .Buttons {
    margin-top: auto;
  }

  @media screen and (min-width: 500px) {
    > figure {
      display: block;
    }
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;

    > figure {
      min-width: $dailyTileMediaWidth;
      max-width: $dailyTileMediaWidth;
    }
  }
}
