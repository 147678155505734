$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.DailyGameUpsellTile {

  &__DailyGameLink {

    margin-top: $marginSm2;

    &:hover {
      img {
        transform: scale(1.1);
      }
    }

    img {
      transition: transform 200ms ease;

      width: 112px;
      max-height: 70px;
    }  

  }
  
}