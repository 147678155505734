$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.GameRequirementsTile {
  position: relative;
  overflow: hidden;
  border-radius: $radius;
  display: flex;
  flex-direction: column;
  user-select: none;
  padding: $spacing;
  font-weight: 300;
  font-size: 12px;

  h4,
  p {
    margin-bottom: $spacing;
  }

  h4 {
    font-weight: lighter;
    font-size: 18px;
  }

  p {
    font-size: 12px;
  }
}

.__Credits {
  li {
    margin: 0;
    display: flex;
    align-items: center;

    +li {
      margin-top: $spacing * 0.5;
    }

    p {
      margin: 0;
    }

    > span {
      display: inline-flex;
      margin-right: $marginSm2;
    }
  }
}

$starWidth: 15px;
$starHeight: 14px;

.__Rating {
  .Icon {
    width: $starWidth;
    height: $starHeight;
  }
}

.__RatingsPicker {
  margin: 0 -$marginSm2;
  margin-left: 0;
  display: flex;
  pointer-events: auto !important;

  @for $i from 1 through 5 {
    > span:nth-child(#{$i}) {
      order: 5 - $i;
    }
  }

  > span {
    margin-top: 3px;
    position: relative;

    .Icon {
      width: $starWidth;
      height: $starHeight;
      cursor: pointer;
    }

    .Icon:last-child {
      top: 0;
      left: 0;
      position: absolute;
      z-index: 1;
      opacity: 0;
    }

    &:hover,
    &.--selected {
      .Icon:last-child {
        opacity: 1;
      }

      ~ span {
        .Icon:last-child {
          opacity: 1;
        }
      }
    }
  }

  .Icon {
    margin: 0 $marginSm0 !important;
  }
}