$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.BonusGameTile {
  position: relative;
  overflow: hidden;
  border-radius: $radius;
  display: flex;
  flex-direction: row;
  user-select: none;
  min-height: 184px;

  &--title {
    margin-bottom: $spacing;

    figure {
      height: 48px;
      width: 48px;
      margin-right: $spacing;
      position: relative;
    }
  }

  > section {
    padding: $spacing * 1.5;
    font-weight: 300;

    &:first-child {
      min-width: 290px;
      max-width: 290px;
      justify-content: flex-start;
    }

    &:last-child {
      flex: auto;
      padding-left: 0;
      padding-right: 0;
    }

    .Button {
      width: 210px;
      margin: 0;
    }
  }

  h3 {
    font-weight: bold;
    font-size: 22px;
  }

  p {
    font-size: 12px;
    font-weight: 600;
  }

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 $marginMd0;

    figure {
      min-width: 198px;
      min-height: 124px;
      max-width: 198px;
      max-height: 124px;
      overflow: hidden;
      position: relative;
      margin-bottom: $marginSm1;
      border-radius: $radiusSm;
    }

    img {
      width: auto;
      max-width: none !important;
      height: 124px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      border-radius: $radiusSm;
    }
  }
}
