$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.GamePage {
  .GamePlayer {
    margin: 0 auto;
  }
}

.GamePage__Breadcrumbs {
  display: flex;
  font-size: 12px;

  li {
    display: block;

    &:not(:first-child) {
      margin-left: $marginSm2;
    }

    &:not(:last-child) {
      &:after {
        content: '>';
        margin-left: $marginSm2;
      }
    }
  }
}

.GamePage__Container {
  flex: 1;
  $asideWidth: $adColumnWidth;

  .GamePage__Container__Aside {
    display: none;

    width: $asideWidth;
    min-width: $asideWidth;
    max-width: $asideWidth;

    position: fixed;
    top: 48px + 16px;
    right: 16px;
    height: calc(100% - 48px - 32px);
    overflow: hidden;

    @media (min-width: 1280px) {
      display: block;
    }

    + .GamePage__Container__Content {
      @media (min-width: 1280px) {
        .Container {
          .Container__Inner {
            padding-right: $asideWidth;
            max-width: 814px + $asideWidth;
          }
        }
      }

      @media (min-width: 1714px) {
        .Container {
          .Container__Inner {
            padding-left: $asideWidth;
            max-width: 814px + $asideWidth + $asideWidth;
          }
        }
      }

      + .GamePage__Container__Aside {
        display: none;
        right: auto;
        left: 16px;

        @media (min-width: 1714px) {
          display: block;
        }
      }
    }
  }
}

.GamePage__AboutSection {
  padding-bottom: $spacing;
  width: 100%;

  @include isDesktopDevice {
    padding-bottom: ($spacing * 2);
  }
}

.GamePage__PlayPanels {
  display: none;
}

.GamePage__HeroAside {
  width: 300px;
  height: 250px;
}

.GamePage__SmallDeviceOverlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ccc;
  z-index: 100;

  @media (max-width: 767px) {
    display: block;
  }
}

.GamePage__SmallDeviceOverlay__Inner {
  width: 166px;

  > * {
    margin: $marginSm1 0;
  }

  h2 {
    font-weight: 700;
    font-size: 28px;
    color: #fff;
    margin: $marginSm2 0;
  }

  img {
    height: 120px !important;
    width: auto !important;
  }
}

.GamePage__ColumnAside {
  display: none;
  position: relative;
  min-width: $adColumnWidth;
  background-color: #EDEDED;

  @media (min-width: 1366px) {
    display: block;
  }

  .PlaywireAside {
    margin-top: 20px;
  }
}

body.game-in-play {
  .ModernAppFooter {
    display: none;
  }
}