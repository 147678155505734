$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.GamePlayerHeader {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;

  .Container {
    @media (max-width: 430px) {
      padding-left: $marginSm3;
      padding-right: $marginSm3;
    }
  }

  .Container__Inner {
    display: flex;
    align-items: center;

    > * {
      flex: 1;
    }
  }

  h1 {
    font-weight: bold;
    text-align: center;
    width: 100%;
    white-space: nowrap;
  }

  aside {
    font-size: 12px;
    display: flex;
    align-items: center;

    &:last-child {
      justify-content: flex-end;
    }

    .Icon {
      width: 1.25rem;
      height: 1.25rem;

      svg {
        width: 100%;
        height: 100%;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    @media (min-width: 431px) {
      font-size: 14px;
    }
  }
}

.GamePlayerHeader__IconToolbar {
  > span {
    display: flex;
    align-items: center;
    margin-right: $marginSm2;
    font-weight: 600;
    user-select: none;

    @media (min-width: 431px) {
      margin-right: $spacing;
    }

    &:last-child {
      margin-right: 0;
    }

    &.--hideMobile {
      order: 9999;
      margin-left: $marginSm2;

      @media (min-width: 431px) {
        margin-left: $spacing;
      }
    }

    &[data-clickable=true] {
      cursor: pointer;
    }

    &[data-disabled=true] {
      opacity: 0.5;
      pointer-events: none;
    }

    .Icon {
      height: 18px;
      width: 18px;
      margin-right: $marginSm0;

      @media (min-width: 431px) {
        height: 20px;
        width: 20px;
        margin-right: $marginSm1;
      }
    }

    .GamePlayerHeader__Label {
      height: 1.25rem;
      line-height: 1.25rem;
      display: inline-flex;
    }
  }
}