$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.DailyGameLayout {
  .App__Header {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
  }

  .App__Main {
    width: 100%;
    position: relative;
    display: flex;
    margin: 0 auto;
    margin-top: 48px;
  }
}
