$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.DailyHero {
  padding: ($spacing * 1.5) 0;
  margin-bottom: $spacing * 1.5;

  .DailyGameTile::after {
    box-shadow: none !important;
  }

  &--empty {
    padding: 0;

    > * {
      margin: 0 auto;
    }
  }

  .DailyHero__Aside {
    width: 300px;
    height: 250px;
  }
}
