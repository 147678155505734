$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.DiscussionContainer {
  border-radius: $radius;
  padding: $spacing;
  font-weight: 300;
}

.Comment,
.LeaveCommentForm {
  img {
    min-width: 46px;
    min-height: 46px;
    border-radius: 100%;
  }

  ul {
    li:not(:last-child) {
      &::after {
        content: ' · ';
        display: inline-block;
        margin: 0 $marginSm2;
      }
    }

    a {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.Comment {
  font-weight: 300;
  font-size: 12px;
}

.LeaveCommentForm {
  width: 100%;

  textarea {
    height: #{1rem + ($marginSm2 * 2)};
    width: 100%;
    padding: $marginSm2;
    border-radius: 100px;
    resize: none;
    overflow: hidden;
  }
}
