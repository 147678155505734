$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.CalendarIcon {
  width: 132px;
  height: 132px;
  border: solid 1px #A4A4A4;
  border-radius: 3px;
  display: flex;
  flex-direction: column;

  > header {
    color: #FFF;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    min-height: 45px;
    max-height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E64531;
  }

  > div {
    text-align: center;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
